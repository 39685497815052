import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/es6-classes",
  "date": "2015-08-24",
  "title": "ES6 CLASSES",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "JavaScript classes are simply syntactic sugar over existing JavaScript prototype chain. But classes give you a cleaner way to organise your code, and make it slightly easier for the beginner or people who are familiar with class oriented languages to read JS and understand."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Let's have a look at how ES5 way of doing the same thing:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function Vehicle(doors, wheels) {
    this.doors = doors;
    this.wheels = wheels;
}

Vehicle.prototype.startEngine = function() {
  this.engineState = 'started';
  console.log('Engine started ..');
};

Vehicle.prototype.stopEngine = function() {
  this.engineState = 'stopped';
  console.log('Engine stopped ..');
};

Vehicle.prototype.drive = function() {
      if(this.engineState == 'started') {
        console.log('driving ..');
      } else {
        console.log('start the engine first!');
      }
    }
}

var car = new Vehicle(2, 4);
car.startEngine();
`}</code></pre>
    <p>{`Now let's look at the ES6 implementation:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`class Vehicle {
  constructor(doors, wheels) {
      this.doors = doors;
      this.wheels = wheels;
  }

  startEngine() {
    this.engineState = 'started';
    console.log('Engine started ..');
  }

  stopEngine() {
    this.engineState = 'stopped';
    console.log('Engine stopped ..');
  }

  drive() {
    if(this.engineState == 'started') {
      console.log('driving ..');
    } else {
      console.log('start the engine first!');
    }
  }
}

var car = new Vehicle(2, 4);
car.startEngine();
`}</code></pre>
    <p>{`You can probably notice the differences between the two. We instantiate our classes the same way we would if we were building our code from an ES5 perspective, but there are a few subtle differences.`}</p>
    <p>{`ES6 classes provide these benefits:`}</p>
    <ul>
      <li parentName="ul">{`They are backwards compatible with much of the current code.`}</li>
      <li parentName="ul">{`Classes make it easier for beginners to get started.`}</li>
      <li parentName="ul">{`Subclassing is supported within the language.`}</li>
      <li parentName="ul">{`Built-in constructors are subclassable.`}</li>
      <li parentName="ul">{`No library for inheritance is needed, anymore; code will become more portable between frameworks.`}</li>
      <li parentName="ul">{`They provide a foundation for advanced features in the future (mixins and more).`}</li>
      <li parentName="ul">{`They help tools that statically analyze code (IDEs, type checkers, style checkers, etc.).`}</li>
    </ul>
    <h2>{`Readability`}</h2>
    <p>{`You don’t realise how messy Javascript is until you work on a large-scale project comprised of more than two developers, ES6 classes come with all of the benefits of pre-existing prototypical inheritance, but require less typing and they make your code easier to understand and resemble closely an object-oriented programming language like Java and its approach to classes.`}</p>
    <Message type="info" title="" content="There are plans to introduce object-oriented syntax like; static, public, private in ES7, but we’ll wait and see if they make the cut." mdxType="Message" />
    <h2>{`Better IDE Support`}</h2>
    <p>{`When coupled with a strong IDE and tooling, ES6 classes can be powerful in that they can be analysed by the IDE and we are given more options for tracing methods (especially when extending parent classes). Webstorm supports classes quite well when used with ES6.`}</p>
    <h2>{`Base Classes`}</h2>
    <p>{`The class definition of ES6 looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`class Base {
  constructor(x, y) {
    this.x = a;
    thisy = y;
  }
  sum() {
    return this.x + this.y;
  }
}
`}</code></pre>
    <p>{`You can only invoke a class via `}<inlineCode parentName="p">{`new`}</inlineCode>{`, not via a function call.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`typeof Base; // 'function'
Base(); // TypeError: Classes can’t be function-called
`}</code></pre>
    <h2>{`Class Expressions`}</h2>
    <p>{`Just like functions, there are two ways to define a class: `}<inlineCode parentName="p">{`declarations`}</inlineCode>{` and `}<inlineCode parentName="p">{`class expression`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const Base = class Self {
  getClassName() {
    return Self.name;
  }
}

let instance = new Base();
instance.getClassName(); // Self
Self.name; // ReferenceError: Self is not defined
`}</code></pre>
    <h2>{`Constructor`}</h2>
    <p>{`The pseudo-method `}<inlineCode parentName="p">{`constructor`}</inlineCode>{` is special, as it defines the function that represents the class:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`Foo === Foo.prototype.constructor; // true
typeof Foo; // 'function'
`}</code></pre>
    <p>{`This is sometimes called `}<inlineCode parentName="p">{`class constructor`}</inlineCode>{`.  It has features that normal constructor functions don’t have, mainly the ability to constructor call its superconstructor via `}<inlineCode parentName="p">{`super()`}</inlineCode>{`.`}</p>
    <h2>{`Static Methods`}</h2>
    <p>{`Static properties (or class properties) are properties of the class itself. If you prefix a method definition with static, you create a class method:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`typeof Foo.staticMethod; // 'function'
Foo.staticMethod(); //'classy'
`}</code></pre>
    <h2>{`Prototype Methods`}</h2>
    <p>{`The prototype properties of Foo are the properties of Foo.prototype. They are usually methods and inherited by instances of Foo.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`typeof Foo.prototype.prototypeMethod //'function'
foo.prototypeMethod() // 'prototypical'
`}</code></pre>
    <h2>{`Getters and Setters`}</h2>
    <p>{`The syntax for getters and setters is just like in ES5 object literals:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`class Bar {
  get foo() {
    return 'foo';
  }
  set foo(value) {
    value;
  }
}

var bar = new Bar();
`}</code></pre>
    <h2>{`Subclassing`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`extends`}</inlineCode>{` clause lets you create a subclass of an existing constructor (which may or may not have been defined via a class):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`class Base {
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }

  sum() {
    return this.x + this.y;
  }
}

class Child extends Base {
  constructor(x, y, z) {
      super(x, y);
      this.z = z;
  }

  sum() {
    return super.x + super.y + this.z;
  }
}

var child = new Child(15, 8 , 3);
child.sum();
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      